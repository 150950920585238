import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';


const Form = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

    const [config, setConfig] = useState({ base_image_archive_url: '' });

  useEffect(() => {
    fetch('/config.json') // This resolves to /config.json in the static folder
      .then((response) => response.json())
      .then((data) => setConfig(data))
      .catch((error) => console.error('Error fetching config:', error));
  }, []);

  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e11.webp`;
  const p12 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e12.webp`;
  const p13 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e13.webp`;
  const p14 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e14.webp`;
  const p15 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/co-curricular-activities/e15.webp`;

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source: p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p5,
      source: p5,
      rows: 1,
      cols: 1,
    },
    {
      src: p6,
      source: p6,
      rows: 1,
      cols: 1,
    },
    {
      src: p7,
      source: p7,
      rows: 1,
      cols: 1,
    },
    {
      src: p8,
      source: p8,
      rows: 1,
      cols: 1,
    },
    {
      src: p9,
      source: p9,
      rows: 1,
      cols: 1,
    },
    {
      src: p10,
      source: p10,
      rows: 1,
      cols: 1,
    },
    {
      src: p11,
      source: p11,
      rows: 1,
      cols: 1,
    },
    {
      src: p12,
      source: p12,
      rows: 1,
      cols: 1,
    },
    {
      src: p13,
      source: p13,
      rows: 1,
      cols: 1,
    },
    {
      src: p14,
      source: p14,
      rows: 1,
      cols: 1,
    },
    {
      src: p15,
      source: p15,
      rows: 1,
      cols: 1,
    },
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Box>
      <Box display={'flex'} justifyContent={'flex-end'} marginBottom={2}>
        {/* <Button
          color="primary"
          size="large"
          endIcon={
            <svg
              width={16}
              height={16}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          }
          onClick={() => openLightbox(0)}
        >
          Open the gallery
        </Button> */}
      </Box>
      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
  );
};


export default Form;