import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';
import Typography from '@mui/material/Typography';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'


const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const ExtraCurricularActivities= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
      <Container>
      <Typography variant='h5' align='center'>
        EXTRA-CURRICULAR ACTIVITIES
        </Typography>
        <br />
        <br />
        <Typography
          component='p'
          align='justify'
          sx={{
            fontWeight: 700,
          }}
        >
            Extracurricular activities are an essential element of school life. Yoga, Music, Craft, Art, Dance and a host of other such activities are woven into the framework of the curriculum to expose students to various other pursuits other than academics. Students have access to news around them through the NIE programmes which the school has subscribed.
        </Typography>
      </Container>
      <Container maxWidth={0.9} paddingTop={'0 !important'}>
        <Form />
      </Container>
      
    </Main>
  );
};

export default ExtraCurricularActivities;
